import React, { Component } from "react";

//images
import arrow1 from "../../../include/images/arrow-1.svg";

//Compoennt

import Form from "../../common/form/form";

class Banking extends Form {
  render() {
    return (
      <>
        <div class="main-container">
          <div class="heading heading-with-btn">
            <h3>Banking & Payment</h3>
            <div class="heading_shap"></div>
            <a href="#" class="btn btn-default">
              {" "}
              Skip <img src={arrow1} alt="" />{" "}
            </a>
          </div>
          <div class="dashboard-box">
            <div class="row">
              <div class="col-md-6">
                <div class="sub-heading">
                  <h5>Online Payment Facility Available :</h5>
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex flex-wrap">
                  <div class="sub-heading me-3">
                    <h5>Sunday Working :</h5>
                  </div>
                  <div class="form-group">
                    <div class="switch-toggle">
                      <span>Yes</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                      />
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Payment Mode :</label>
                  <select class="form-control">
                    <option> Select Mode</option>
                    <option> 2001</option>
                    <option> 2002</option>
                    <option> 2003</option>
                    <option> 2004</option>
                    <option> 2005</option>
                    <option> 2006</option>
                    <option> 2007</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Type of Account :</label>
                  <select class="form-control">
                    <option> Select Type</option>
                    <option> 2001</option>
                    <option> 2002</option>
                    <option> 2003</option>
                    <option> 2004</option>
                    <option> 2005</option>
                    <option> 2006</option>
                    <option> 2007</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("ifscCode", "", "IFSC Code")}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("branchName", "", "Branch Name")}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("swiftCode", "", "Swift Code")}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("gstIn", "", "GSTIN ")}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("panNo", "", "PAN NO.")}
              </div>
              <div class="col-md-6 col-lg-4">
                {this.renderInput("upiNumber", "", "UPI Number")}
              </div>
              <div class="col-md-12">
                <div class="sub-heading">
                  <h5>Upload Any Of The Following Documents ( Optional ) :</h5>
                </div>
              </div>
              <div class="col-md-6 col-lg-5">
                <div class="form-group">
                  <label>Upload Cancelled Cheque :</label>
                  <input type="file" placeholder="" class="form-control" />
                </div>
              </div>
              <div class="col-md-6 col-lg-5">
                <div class="form-group">
                  <label>Upload Bank Statment :</label>
                  <input type="file" placeholder="" class="form-control" />
                </div>
              </div>
              <div class="col-md-6 col-lg-5">
                <div class="form-group">
                  <label>Upload Bank Statment :</label>
                  <input type="file" placeholder="" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="show-more-btn text-center">
                  <a href="#" class="btn btn-default">
                    Finish <img src={arrow1} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Banking;
